'use strict';

// Wrap для каждой буквы(span) и слова(div)
(function () {
  let span = $('.js-text-span');
  if (span.length) {
    span.html(function (i, html) {
      return html.replace(/[^\s"]+|"([^"]*)"/gi, '<div>$&</div>');
    });
    $('.js-text-span div').html(function (i, html) {
      return html.replace(/(\S)/g, '<span><span>$&</span></span>');
    });
  }
})();

// Плавный скроллинг к элементу
(function () {
  let scrollDown = $('.js-scroll-up');

  if (scrollDown.length) {
    scrollDown.each(function () {
      $(this).click(function () {

        let
          id = $(this).attr("href"),
          paddingTop = $(this).attr("data-padding");

        if (typeof paddingTop === typeof undefined || paddingTop === false || isNaN(paddingTop)) {
          if (window.matchMedia("(max-width: 1000px)").matches) {
            paddingTop = 50;
          } else {
            paddingTop = 0;
          }
        }

        $('body, html').animate({scrollTop: $(id).offset().top - (+paddingTop)}, 'slow');

        return false;

      })
    });
  }

})();

//  Раскрывает выпадающий блок
(function () {
  /**
   * @param  {Object} path             Путь до елемента с которого всё начинается (стрелка)
   * @param  {String} parent_name      Класс родителя откуда от места начала (".exampleClass")
   * @param  {String} children_in      Класс скрытого блока (".exampleClass")
   * @param  {String | Number} duration_h  Время исполнения анимации (slow|normal|fast)
   * @param  {Boolean} hidden_path     Скрывать path элемент (true) или нет (false)
   * @param  {Number} a_time           Задаёт задержку раскрытия в милисекундах
   * @return {undefined}
   */
  function wiyzjalka(path, parent_name, children_in, duration_h, hidden_path, a_time) {
    var a_time = (!a_time) ? 0 : a_time;
    path.each(function () {
      var thisHand = $(this);

      if ($(this).parents(parent_name).find(children_in).find(".js-active").length > 0 || $(this).hasClass('js-open')) {
        $(this).parents(parent_name).addClass("js-open");
        $(this).parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
        if ("true" === $(this).parents(parent_name).find(children_in).attr('aria-hidden')) {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'false');
        } else {
          $(this).parents(parent_name).find(children_in).attr('aria-hidden', 'true');
        }
      }

      function openPandora() {
        if ($(children_in).find('.hide-box').length) {
          var dMSec = (duration_h === "slow") ? 600
            : (duration_h === "normal") ? 400
              : (duration_h === "fast") ? 200 : 0
          setTimeout(function () {
            toolboxSetClass($('.hide-box'));
          }, dMSec + a_time);
        }
        if (hidden_path) {
          thisHand.hide();
        }

        path.parents(parent_name).find(children_in).stop(true, false).slideUp(duration_h);
        path.parents(parent_name).removeClass("js-open");
        path.parents(parent_name).removeClass("js-active");
        if (thisHand.parents(parent_name).find(children_in).is(children_in)) {
          if (thisHand.parents(parent_name).find(children_in).is(":visible")) {
            thisHand.parents(parent_name).removeClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().slideUp(duration_h, function () {
              // console.log(thisHand.css());
              thisHand.css("height", "auto");
            });
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
          } else {
            if (thisHand.parents(parent_name).find(children_in).attr('aria-hidden') === "true") {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'false');
            } else {
              thisHand.parents(parent_name).find(children_in).attr('aria-hidden', 'true');
            }
            thisHand.parents(parent_name).addClass("js-open");
            thisHand.parents(parent_name).find(children_in).stop().delay(a_time).slideDown(duration_h);
          }
        }
      }

      thisHand.bind("click", openPandora);
      thisHand.on("keydown", function (e) {
        if (e.keyCode === 13) {
          openPandora();
        }
      });
    });
  }

  window.collapse = {
    wiyzjalka: wiyzjalka
  }
})();

// Зацикливание фокуса для доступности в модалках
(function () {
  const
    MODAL_ELEMENT_FOCUSED = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex="0"]',
    MODAL_FOCUS_TRAP_CLASS_NAME = 'js-trap-focus';

  function setFocusTrap(focusWrap) {
    let
      focusedElements = focusWrap.querySelectorAll(MODAL_ELEMENT_FOCUSED),
      firstFocusElement = focusedElements[0],
      lastFocusElement = focusedElements[focusedElements.length - 1],
      defaultFocusElements = focusWrap.querySelectorAll('.' + MODAL_FOCUS_TRAP_CLASS_NAME);

    if (defaultFocusElements.length === 0) {
      let
        createFocusTrap = window.utils.createDivWithClass(MODAL_FOCUS_TRAP_CLASS_NAME),
        cloneCreateFocusTrap = createFocusTrap.cloneNode();

      cloneCreateFocusTrap.setAttribute('tabindex', '0');
      createFocusTrap.setAttribute('tabindex', '0');

      focusWrap.appendChild(createFocusTrap);
      focusWrap.insertAdjacentElement('afterbegin', cloneCreateFocusTrap);

      // Зацикливание фокуса внутри модалки
      let focusTraps = focusWrap.children;

      if (!lastFocusElement) {
        lastFocusElement = focusTraps[focusTraps.length - 1];
      }
      if (!firstFocusElement) {
        firstFocusElement = focusTraps[0];
      }

      firstFocusElement.focus();

      focusTraps[focusTraps.length - 1].addEventListener('focus', function () {
        firstFocusElement.focus();
      });
      focusTraps[0].addEventListener('blur', function () {
        lastFocusElement.focus();
      });
    } else {
      defaultFocusElements.forEach(function (current) {
        current.remove();
      })
    }

  }

  window.focusTrap = {
    setFocusTrap: setFocusTrap
  }

})();

// Модальное окно
(function () {
  const
    OVERLAY_CLASS_NAME = 'modal-overlay',
    MODAL_BUTTON_CLOSE_CLASS_NAME = 'js-modal-close',
    TRANSITION_TIME = 0.4;

  let createOverModal = window.utils.createDivWithClass(OVERLAY_CLASS_NAME);

  document.body.appendChild(createOverModal);

  let alexModal = function (modal) {
    let
      buttonModalShow = modal,
      modalWindow = document.querySelector(buttonModalShow.dataset.id),
      buttonModalHide = modalWindow.querySelectorAll('.' + MODAL_BUTTON_CLOSE_CLASS_NAME),
      modalOverlay = document.querySelector('.' + OVERLAY_CLASS_NAME),
      body = document.body;

    function openModal() {
      let scrollBarWidth = window.utils.getScrollbarWidth();

      body.classList.add('modal-open');

      document.documentElement.style.setProperty('--scroll-width', scrollBarWidth + 'px');
      modalWindow.style.paddingRight = scrollBarWidth + 'px';

      modalOverlay.style.display = 'block';
      modalWindow.style.display = 'block';
      setTimeout(function () {
        modalOverlay.classList.add('this-show');

        modalWindow.classList.remove('modal-hidden');
      }, 100);

      document.addEventListener('keydown', documentKeyDownHandler);
      modalWindow.addEventListener('click', modalWindowClickHandler);

      // Доступность для модалк
      modalWindow.removeAttribute('aria-hidden');

      window.focusTrap.setFocusTrap(modalWindow);

    }

    function closeModal() {
      modalWindow.classList.add('modal-hidden');

      modalOverlay.classList.remove('this-show');

      // Вешаю отсчёт времени для отработки CSS анимации
      setTimeout(function () {
        modalOverlay.style.display = 'none';

        body.classList.remove('modal-open');
        document.documentElement.style.setProperty('--scroll-width', '0');

        modalWindow.style.display = 'none';
        modalWindow.style.paddingRight = '0';
      }, TRANSITION_TIME * 1000);

      // Доступность для модалк
      modalWindow.setAttribute('aria-hidden', 'true');

      // Убираю все события после закрытия модалки
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.removeEventListener('click', closeModal);
        });
      }
      modalWindow.removeEventListener('click', modalWindowClickHandler);
      document.removeEventListener('keydown', documentKeyDownHandler);

      window.focusTrap.setFocusTrap(modalWindow);
    }

    function documentKeyDownHandler(evt) {
      if (evt.key === window.utils.keyEscape && !evt.target.matches('input, textarea, select')) {
        closeModal();
      }
    }

    function modalWindowClickHandler(evt) {
      if (evt.target === modalWindow) {
        closeModal();
      }
    }

    buttonModalShow.addEventListener('click', function (evt) {
      // Вешает preventDefault если элемент ссылка
      if (buttonModalShow.tagName === 'A') {
        evt.preventDefault();
      }

      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    });

    buttonModalShow.addEventListener('keydown', function (evt) {
      if (evt.key === window.utils.keyEnter && !buttonModalShow.matches('button, a')) {
        // Вешает preventDefault если элемент ссылка
        if (buttonModalShow.tagName === 'A') {
          evt.preventDefault();
        }

        openModal();
        if (buttonModalHide) {
          buttonModalHide.forEach(function (current) {
            current.addEventListener('click', closeModal);
          });
        }
      }
    });

    if (modalWindow.classList.contains('open')) {
      openModal();

      // После клика по кнопке открытия модалки
      // добавляю событие клика по кнопке закрытия
      if (buttonModalHide) {
        buttonModalHide.forEach(function (current) {
          current.addEventListener('click', closeModal);
        });
      }
    }
  };


  window.modal = {
    alexModal: alexModal
  }

})();

// Добавление класса к header в зависимости от скроллинга страницы. Только для главной страницы
(function () {
  let headerSite = document.querySelector('.js-header-sticky');
  if (headerSite) {
    // let      headerSiteHeight = headerSite.clientHeight;

    if (window.matchMedia("(min-width: 480px)").matches) {
      document.addEventListener('scroll', function (evt) {
        let positivePositionAxisY = document.querySelector('.main-about').clientHeight;
        let headerSiteHeight = document.querySelector('.main-about').getBoundingClientRect().y * (-1);

        if (headerSiteHeight > positivePositionAxisY) {
          headerSite.classList.add('header--sticky');
        } else {
          headerSite.classList.remove('header--sticky');
        }

        if (headerSiteHeight > 300) {
          headerSite.classList.add('header-temp');
        } else {
          headerSite.classList.remove('header-temp');
        }
      });
    }

    if (window.matchMedia("(max-width: 480px)").matches) {
      headerSite.classList.add('header--sticky');
    }
  }

})();

// Плавный скроллинг к элементу
(function () {
  let scrollDown = $('.js-scroll-down');

  if (scrollDown.length) {
    scrollDown.each(function () {
      $(this).click(function () {

        let id = $(this).attr("href"),
          paddingTop = $(this).attr("data-padding");

        if (typeof paddingTop === typeof undefined || paddingTop === false || paddingTop === NaN) {
          if (window.matchMedia("(max-width: 1000px)").matches) {
            paddingTop = 50;
          } else {
            paddingTop = 0;
          }
        }

        $('body, html').animate({scrollTop: $(id).offset().top - (+paddingTop)}, 'slow');

        return false;

      })
    });
  }

})();

// Убирает фокус с элементов если активна мышка
(function () {
  // Let the document know when the mouse is being used
  document.body.addEventListener('mousedown', function () {
    document.body.classList.add('using-mouse');
  });

  // Re-enable focus styling when Tab is pressed
  document.body.addEventListener('keydown', function (event) {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse');
    }
  });
})();

// Поиск по сайту в шапке
(function () {
  let searchButton = document.querySelector('.js-search-btn');

  if (searchButton) {
    searchButton.addEventListener('focus', function (evt) {
      evt.preventDefault();

      searchButton.parentNode.querySelector('input').focus();
    })
  }
})();

// Анимация фокуса на input, доработки
(function () {

  window.addEventListener('load', function () {

    let inputFocusWrap = document.querySelectorAll('.input-focus');

    if (inputFocusWrap.length) {

      function inputBlurHandler(evt, index) {
        if (evt.target && evt.target.matches('input, textarea') && evt.target.value !== '') {
          inputFocusWrap[index].classList.add('text-in');
        } else {
          inputFocusWrap[index].classList.remove('text-in');
        }
      }

      inputFocusWrap.forEach(function (current, index) {
        let inputFocus = current.querySelector('input, textarea, select');

        inputFocus.addEventListener('blur', function (evt) {
          inputBlurHandler(evt, index);
        });

        inputFocus.dispatchEvent(new Event('blur'));
      });
    }
  });

})();

// Переключение табов
(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-tab-target').children),
      tabActive = tabParent.querySelector('.js-tab-item .active');

    let calculateHeight = function () {
      let targetActive = tabParent.querySelector('.js-tab-target .active');

      setTimeout(function () {
        tabParent.style.minHeight = targetActive.offsetHeight + 'px';
      }, 500);
    };

    // calculateHeight();

    let tabIndicator = window.utils.createDivWithClass('tab-indicator');
    tabParent.querySelector('.js-tab-item').appendChild(tabIndicator);

    let calculateYAxisIndicator = function () {
      if (tabParent.dataset.indicator) {
        tabIndicator.style.transform = 'translateY(' + tabActive.offsetTop + 'px)';
        tabIndicator.style.height = tabActive.clientHeight + "px";
      }
    };
    calculateYAxisIndicator();

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
      calculateYAxisIndicator();
      // calculateHeight();

      // window.tooltip.tooltipPosition();
    }

    tabArray.forEach(function (current, index) {
      if (!current.classList.contains('disabled')) {
        current.addEventListener('click', function () {
          toggleTabEvent(current, index);
        });

        current.addEventListener('keydown', function (evt) {
          if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
            toggleTabEvent(current, index);
          }
        });
      }
    });
  };

  let tabRemove = function (tabParent) {
    tabParent.querySelector('.tab-indicator').remove();
  };

  window.tab = {
    tabWork: tabWork,
    tabRemove: tabRemove
  }

})();

// Переключение табов при наведении
(function () {
  let tabWork = function (tabParent) {
    let
      tabArray = window.utils.getArrayFrom(tabParent.querySelector('.js-hover-item').children),
      tabTargetArray = window.utils.getArrayFrom(tabParent.querySelector('.js-hover-target').children),
      tabActive = tabParent.querySelector('.js-hover-item .active');

    let tabIndicator = window.utils.createDivWithClass('tab-indicator');
    tabParent.querySelector('.js-hover-item').appendChild(tabIndicator);

    let calculateYAxisIndicator = function () {
      if (tabParent.dataset.indicator) {
        tabIndicator.style.transform = 'translateY(' + ((tabActive.offsetTop + (tabActive.clientHeight / 2)) - (tabIndicator.clientHeight / 2)) + 'px)';
      }
    };
    calculateYAxisIndicator();

    function toggleTabEvent(current, index) {
      let activeArray = Array.from(tabParent.querySelectorAll('.active'));

      activeArray.forEach(function (activeCurrent) {
        activeCurrent.classList.remove('active');
      });

      current.classList.add('active');
      tabTargetArray[index].classList.add('active');

      tabActive = current;
      calculateYAxisIndicator();
    }


    tabArray.forEach(function (current, index) {
      if (!current.classList.contains('disabled')) {
        current.addEventListener('mouseover', function () {
          toggleTabEvent(current, index);
        });

        current.addEventListener('keydown', function (evt) {
          if (evt.key === window.utils.keyEnter || evt.key === window.utils.keySpace) {
            toggleTabEvent(current, index);
          }
        });
      }
    });
  };

  let tabRemove = function (tabParent) {
    tabParent.querySelector('.tab-indicator').remove();
  };

  window.tabHover = {
    tabHoverWork: tabWork,
    tabHoverRemove: tabRemove
  }

})();

(function () {
  let t = window.performance.now();

  for (let i = 0, o = 0; i < 1000000; i++) {
    o++;
  }

  let
    perf = window.performance.now() - t,
    np = 7,
    cond = (perf < np),
    cssDelay = (cond) ? 1.2 : 0;

  if (!cond) {
    document.body.classList.add("low-performance");
  }

  document.documentElement.style.setProperty('--fullpage-delay', cssDelay + 's');

  window.power = {
    performance: perf,
    delay: np,
    low: cond,
  };

})();

// Fullpage анимация
if (window.matchMedia("(min-width: 1000px)").matches && document.querySelector('#fullpage')) {
  (function () {
    let fullpageDelay = 1000; // Задержка для переключения классов
    let buttonHideAnimation = {minWidth: 0, width: 0, padding: 0, whiteSpace: "nowrap", opacity: 0, ease: Expo.easeIn, delay: 0.2};

    // Добавление | удаление классов с задержкой
    let toggleClassWithDelay = function (e, cls, remove) {
      if (remove) {
        setTimeout(function () {
          $(e).removeClass(cls);
        }, fullpageDelay + 800)
      } else {
        setTimeout(function () {
          $(e).addClass(cls);
        }, fullpageDelay)
      }
    };

    // 1 - секция (СКРЫТИЕ)
    let firstPageHide = function () {
      let
        tl = new TimelineMax(),
        tl2 = new TimelineMax();

      // Синий блок
      tl
        .to('.section--main .main-blue-block', 0.75, {width: "120%", ease: Expo.easeIn})
        .to('.section--main .main-blue-block', 0.75, {left: "100%", ease: Expo.easeInOut});

      // Красный слайдер
      tl2
        .to('.section--main .main-section__tab-wrap', 0.77, {width: "300%", ease: Expo.easeIn})
        .to('.section--main .main-section__tab-wrap', 0.77, {left: "100%", ease: Expo.easeInOut});

      // Текст
      TweenMax.staggerTo('.section--main .js-text-span-wrap .js-text-span span span', 0.7, {x: -100, ease: Expo.easeInOut}, 0.015);
      TweenMax.to('.section--main .main-slider-item .js-text-span div', 0.7, {y: 100, opacity: 0, ease: Expo.easeInOut});
      TweenMax.to('.section--main .line-top-title__line', 0.6, {width: 0, x: 200, ease: Expo.easeInOut, delay: 0.1});

      // Кнопки
      TweenMax.to('.section--main .js-tween-btn', 0.4, buttonHideAnimation);

      // Главная картинка
      TweenMax.to('.section--main .slider-image-list', 0.5, {width: 0, ease: Expo.easeInOut, delay: 0.75});

      // Навигация у слайдера
      TweenMax.to('.section--main .main-thumbnails', 0.5, {opacity: 0, ease: Expo.easeInOut, delay: 0.15});
    };

    // 1 - секция (ПОКАЗ)
    let firstPageShow = function () {
      let
        tl = new TimelineMax({delay: 0.1}),
        tl2 = new TimelineMax();

      // Синий блок
      tl
        .fromTo('.section--main .main-blue-block', 0.75, {left: "100%"}, {left: 0, ease: Expo.easeIn})
        .fromTo('.section--main .main-blue-block', 0.75, {width: "300%"}, {width: "42.5%", ease: Expo.easeInOut});

      // Красный слайдер
      tl2
        .fromTo('.section--main .main-section__tab-wrap', 0.75, {left: "100%"}, {left: 0, ease: Expo.easeIn})
        .fromTo('.section--main .main-section__tab-wrap', 0.75, {width: "300%"}, {width: "100%", ease: Expo.easeInOut});

      // Текст
      TweenMax.staggerFromTo('.section--main .js-text-span-wrap .js-text-span span span', 0.7, {x: 150}, {x: 0, ease: Expo.easeInOut, delay: 0.65}, 0.015);
      TweenMax.fromTo('.section--main .main-slider-item .js-text-span div', 0.7, {y: -100, opacity: 0}, {y: 0, opacity: 1, ease: Expo.easeInOut, delay: 1});
      TweenMax.fromTo('.section--main .line-top-title__line', 1, {width: 0, x: -50,}, {clearProps: "all", ease: Expo.easeInOut, delay: 0.3});

      // Главная картинка
      TweenMax.fromTo('.section--main .slider-image-list', 0.5, {width: 0}, {width: "57.5%", ease: Expo.easeInOut, delay: 0.85});

      // Кнопки
      TweenMax.fromTo('.section--main .js-tween-btn', 0.4, buttonHideAnimation, {clearProps: "all", ease: Expo.easeInOut, delay: 1});

      // Навигация у слайдера
      TweenMax.fromTo('.section--main .main-thumbnails', 0.5, {opacity: 0}, {opacity: 1, ease: Expo.easeInOut, delay: 1.25});
    };

    // 2 - секция (СКРЫТИЕ)
    let secondPageHide = function () {
      let
        tl = new TimelineMax(),
        tl2 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerTo('.section--about .js-tween-title span span', 0.7, {x: 80, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.01);

      // Текст
      tl.staggerTo('.section--about .js-text-normal div, .section--about .styled-list > li, .section--about .blockquote', 0.6, {x: 200, opacity: 0, ease: Expo.easeIn}, 0.005);

      // Картинка
      TweenMax.to('.section--about .about-media__img-wrap > span', 0.5, {width: "100%", ease: Expo.easeIn, delay: 0.3});

      // Кнопки
      TweenMax.to('.section--about .js-tween-btn', 0.4, buttonHideAnimation);

      // Фон
      TweenMax.to('.section--about .about-section-bg', 0.8, {width: 0, ease: Expo.easeIn, delay: 0.1});
    };

    // 2 - секция (ПОКАЗ)
    let secondPageShow = function () {
      let
        tl = new TimelineMax(),
        tl2 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerFromTo('.section--about .js-tween-title span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.01);

      // Текст
      tl.staggerFromTo('.section--about .js-text-normal div, .section--about .styled-list > li, .section--about .blockquote', 1.3, {x: -100, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
        delay: 0.8
      }, 0.01);

      // Картинка
      TweenMax.fromTo('.section--about .about-media__img-wrap > span', 1, {width: "100%"}, {width: 0, ease: Expo.easeOut, delay: 0.9});

      // Кнопки
      TweenMax.fromTo('.section--about .js-tween-btn', 0.4, buttonHideAnimation, {clearProps: "all", ease: Expo.easeInOut, delay: 0.3});

      // Фон
      TweenMax.fromTo('.section--about .about-section-bg', 0.8, {width: 0}, {width: "74%", ease: Expo.easeInOut, delay: 0.6});
    };

    // 3 - секция (СКРЫТИЕ)
    let thirdPageHide = function () {
      let
        tl = new TimelineMax(),
        tl2 = new TimelineMax();

      // Текст
      tl.staggerTo('.section--product .js-text-span span span', 0.7, {x: 80, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.01);

      // Кнопки
      TweenMax.to('.section--product .js-tween-btn', 0.5, buttonHideAnimation);

      // Контроль слайдера
      TweenMax.staggerTo('.section--product .js-tween-control', 0.5, {y: 200, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.1);

      // Фон для слайдера
      TweenMax.staggerTo('.section--product .active .js-tween-slider-bg, .section--product .active + .tns-item .js-tween-slider-bg', 0.7, {
        maxWidth: 0,
        x: 900,
        ease: Expo.easeIn,
        delay: 0
      }, 0.1);

      // Плашка в слайдере
      TweenMax.staggerTo('.section--product .active .js-tween-slider-content, .section--product .active + .tns-item .js-tween-slider-content', 0.8, {
        maxWidth: 0,
        x: -150,
        ease: Expo.easeInOut,
        delay: 0.1
      }, 0.1);

      // Плашка в слайдере контент
      TweenMax.to('.section--product .active .js-tween-slider-element, .section--product .active + .tns-item .js-tween-slider-element', 0.2, {x: 100, ease: Expo.easeIn, delay: 0});

      // Картинка в слайдере
      TweenMax.staggerTo('.section--product .active .js-tween-slider-img, .section--product .active + .tns-item .js-tween-slider-img', 0.4, {
        scale: 0.5,
        opacity: 0,
        ease: Expo.easeIn,
        delay: 0.1
      }, 0.1);

      // Навигация в слайдере
      TweenMax.to('.section--product .js-tween-slider-nav', 0.6, {maxWidth: 0, ease: Expo.easeInOut, delay: 0});

      // Фон
      TweenMax.to('.section--product .js-tween-bg', 0.8, {width: 0, ease: Expo.easeIn, delay: 0.1});
    };

    // 3 - секция (ПОКАЗ)
    let thirdPageShow = function () {
      let
        tl = new TimelineMax(),
        tl2 = new TimelineMax();

      // Текст
      tl.staggerFromTo('.section--product .js-text-span span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.01);

      // Кнопки
      TweenMax.fromTo('.section--product .js-tween-btn', 0.4, buttonHideAnimation, {clearProps: "all", ease: Expo.easeInOut, delay: 0.3});

      // Контроль слайдера
      TweenMax.staggerFromTo('.section--product .js-tween-control', 0.4, {y: -200, opacity: 0}, {y: 0, opacity: 1, ease: Expo.easeInOut, delay: 0.6}, -0.1);

      // Фон для слайдера
      TweenMax.staggerFromTo('.section--product .active .js-tween-slider-bg, .section--product .active + .tns-item .js-tween-slider-bg', 0.8, {
        maxWidth: 0,
        x: -100
      }, {maxWidth: "100%", x: 0, ease: Expo.easeOut, delay: 0.6}, 0.1);

      // Плашка в слайдере
      TweenMax.staggerFromTo('.section--product .active .js-tween-slider-content, .section--product .active + .tns-item .js-tween-slider-content', 0.8, {
        maxWidth: 0,
        x: -250
      }, {maxWidth: "100%", x: 0, ease: Expo.easeOut, delay: 0.4}, 0.1);

      // Плашка в слайдере контент
      TweenMax.fromTo('.section--product .active .js-tween-slider-element, .section--product .active + .tns-item .js-tween-slider-element', 0.4, {x: 100}, {
        x: 0,
        ease: Expo.easeIn,
        delay: 0.5
      });

      // Картинка в слайдере
      TweenMax.staggerFromTo('.section--product .active .js-tween-slider-img, .section--product .active + .tns-item .js-tween-slider-img', 0.6, {scale: 0, opacity: 0}, {
        scale: 1,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0.4
      }, 0.1);

      // Навигация в слайдере
      TweenMax.fromTo('.section--product .js-tween-slider-nav', 0.6, {maxWidth: 0}, {maxWidth: "100%", ease: Expo.easeInOut, delay: 0.6});

      // Фон
      TweenMax.fromTo('.section--product .js-tween-bg', 0.8, {width: 0}, {width: "38%", ease: Expo.easeInOut, delay: 0.1});
    };

    // 4 - секция (СКРЫТИЕ)
    let fourthPageHide = function () {
      let
        tl = new TimelineMax({delay: 0.05}),
        tl2 = new TimelineMax(),
        tl3 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerTo('.section--privilege .js-tween-title span span', 0.7, {x: 80, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.0085);

      // Текст
      tl.staggerTo('.section--privilege .js-tween-text', 0.6, {
        x: 80,
        opacity: 0,
        ease: Expo.easeInOut,
      }, 0.1);

      // Иконки
      tl3.staggerTo('.section--privilege .js-tween-icon', 0.7, {
        x: 40,
        opacity: 0,
        scale: 0,
        ease: Expo.easeInOut,
      }, 0.1);

      // Картинка
      TweenMax.to('.section--privilege .js-tween-img-bg', 0.6, {width: "100%", ease: Expo.easeIn, delay: 0.2});

      // Фон
      TweenMax.to('.section--privilege .privilege-section-bg', 0.8, {width: 0, ease: Expo.easeInOut, delay: 0.2});
    };

    // 4 - секция (ПОКАЗ)
    let fourthPageShow = function () {
      let
        tl = new TimelineMax({delay: 0.8}),
        tl2 = new TimelineMax(),
        tl3 = new TimelineMax({delay: 0.95});

      // Текст Заголовки
      tl2.staggerFromTo('.section--privilege .js-tween-title span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.01);

      // Текст
      tl.staggerFromTo('.section--privilege .js-tween-text', 1.2, {x: -120, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
      }, 0.1);

      // Иконки
      tl3.staggerFromTo('.section--privilege .js-tween-icon', 1, {x: -40, opacity: 0, scale: 0}, {
        x: 0,
        opacity: 1,
        scale: 1,
        ease: Expo.easeOut,
      }, 0.1);

      // Картинка
      TweenMax.fromTo('.section--privilege .js-tween-img-bg', 0.7, {width: "100%"}, {width: 0, ease: Expo.easeOut, delay: 0.3});

      // Фон
      TweenMax.fromTo('.section--privilege .privilege-section-bg', 0.8, {width: 0}, {width: "70.5%", ease: Expo.easeOut, delay: 0.3});
    };

    // 5 - секция (СКРЫТИЕ)
    let fifthPageHide = function () {
      let
        tl = new TimelineMax({delay: 0.05}),
        tl2 = new TimelineMax(),
        tl3 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerTo('.section--service .js-tween-title span span', 0.7, {x: 80, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.0085);

      // Текст
      tl.staggerTo('.section--service .js-tween-text', 0.6, {
        x: 80,
        opacity: 0,
        ease: Expo.easeInOut,
      }, -0.1);

      // Иконки
      tl3.to('.section--service .js-tween-icon', 0.6, {
        scale: 0,
        ease: Expo.easeInOut,
      }, -0.1);

      // Картинка
      TweenMax.staggerTo('.section--service .about-media__img-wrap > span', 0.6, {width: "100%", ease: Expo.easeIn, delay: 0}, 0.1);

      // Фон
      TweenMax.staggerTo('.section--service .js-tween-bg', 0.7, {width: 0, ease: Expo.easeIn, delay: 0.4}, -0.1);
    };

    // 5 - секция (ПОКАЗ)
    let fifthPageShow = function () {
      let
        tl = new TimelineMax({delay: 0.8}),
        tl2 = new TimelineMax(),
        tl3 = new TimelineMax({delay: 0.8});

      // Текст Заголовки
      tl2.staggerFromTo('.section--service .js-tween-title span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.01);

      // Текст
      tl.staggerFromTo('.section--service .js-tween-text', 1.2, {x: -100, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
      }, 0.05);

      // Иконки
      tl3.staggerFromTo('.section--service .js-tween-icon', 0.6, {scale: 0}, {
        scale: 1,
        ease: Expo.easeOut,
      }, 0.3);

      // Картинка
      TweenMax.staggerFromTo('.section--service .about-media__img-wrap > span', 0.6, {width: "100%"}, {width: 0, ease: Expo.easeOut, delay: 0.6}, -0.1);

      // Фон
      TweenMax.staggerFromTo('.section--service .js-tween-bg', 0.6, {width: 0}, {width: "100%", ease: Expo.easeOut, delay: 0.4}, 0.2);
    };

    // 6 - секция (СКРЫТИЕ)
    let sixthPageHide = function () {
      let
        tl = new TimelineMax({delay: 0.4});

      // Текст Заголовки
      tl.staggerTo('.section--useful .js-tween-title span span', 0.7, {x: 80, opacity: 0, ease: Expo.easeIn, delay: 0}, -0.0085);

      // Блоки в слайдере
      TweenMax.staggerTo('.section--useful .slider-active .js-tween-slider-bg, .section--useful .slider-active + .tns-item .js-tween-slider-bg, .section--useful .slider-active + .tns-item + .tns-item .js-tween-slider-bg', 0.7, {
        x: -40,
        maxWidth: 0,
        opacity: 0,
        ease: Expo.easeIn,
        delay: 0.1
      }, 0.05);

      // Текст в слайдере
      TweenMax.staggerTo('.section--useful .slider-active .js-tween-text, .section--useful .slider-active + .tns-item .js-tween-text, .section--useful .slider-active + .tns-item + .tns-item .js-tween-text', 0.6, {
        x: 80,
        opacity: 0,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.05);

      // Точка в слайдере
      TweenMax.staggerTo('.section--useful .slider-active .js-tween-dot, .section--useful .slider-active + .tns-item .js-tween-dot, .section--useful .slider-active + .tns-item + .tns-item .js-tween-dot', 0.5, {
        scale: 0,
        ease: Expo.easeIn,
        delay: 0
      }, 0.05);

      // Табы
      TweenMax.staggerTo('.section--useful .js-tween-tab', 0.6, {x: 80, opacity: 0, ease: Expo.easeInOut, delay: 0.1}, -0.2);

      // Стрелки для слайдера
      TweenMax.staggerTo('.section--useful .js-tab-target > li.active .js-tween-control', 0.6, {x: 80, opacity: 0, ease: Expo.easeInOut, delay: 0.1}, -0.2);

      // Навигация в слайдере
      TweenMax.to('.section--useful .js-tween-slider-nav', 0.6, {maxWidth: 0, ease: Expo.easeInOut, delay: 0.2});

      // Кнопки
      TweenMax.to('.section--useful .js-tween-btn', 0.5, buttonHideAnimation);

      // Фон
      TweenMax.to('.section--useful .js-tween-bg', 0.7, {top: "100%", ease: Expo.easeInOut, delay: 0.4});
    };

    // 6 - секция (ПОКАЗ)
    let sixthPageShow = function () {
      let
        tl = new TimelineMax({delay: 0});

      // Текст Заголовки
      tl.staggerFromTo('.section--useful .js-tween-title span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
      }, 0.01);

      // Блоки в слайдере
      TweenMax.staggerFromTo('.section--useful .slider-active .js-tween-slider-bg, .section--useful .slider-active + .tns-item .js-tween-slider-bg, .section--useful .slider-active + .tns-item + .tns-item .js-tween-slider-bg', 0.8, {
        x: -40,
        maxWidth: 0,
        opacity: 0
      }, {
        x: 0,
        maxWidth: "100%",
        opacity: 1,
        ease: Expo.easeOut,
        delay: 0.4
      }, 0.1);

      // Текст в слайдере
      TweenMax.staggerFromTo('.section--useful .slider-active .js-tween-text, .section--useful .slider-active + .tns-item .js-tween-text, .section--useful .slider-active + .tns-item + .tns-item .js-tween-text', 0.8, {
        x: -80,
        opacity: 0
      }, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
        delay: 0.45
      }, 0.08);

      // Точка в слайдере
      TweenMax.staggerFromTo('.section--useful .slider-active .js-tween-dot, .section--useful .slider-active + .tns-item .js-tween-dot, .section--useful .slider-active + .tns-item + .tns-item .js-tween-dot', 0.8, {scale: 0}, {
        scale: 1,
        ease: Expo.easeOut,
        delay: 1
      }, 0.1);

      // Табы
      TweenMax.staggerFromTo('.section--useful .js-tween-tab', 0.6, {x: -80, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut, delay: 0.8}, -0.2);

      // Стрелки для слайдера
      TweenMax.staggerFromTo('.section--useful .js-tab-target > li.active .js-tween-control', 0.6, {x: -80, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut, delay: 0.3}, -0.2);

      // Навигация в слайдере
      TweenMax.fromTo('.section--useful .js-tween-slider-nav', 0.6, {maxWidth: 0}, {maxWidth: "100%", ease: Expo.easeOut, delay: 0.6});

      // Кнопки
      TweenMax.fromTo('.section--useful .js-tween-btn', 0.4, buttonHideAnimation, {clearProps: "all", ease: Expo.easeInOut, delay: 0.3});

      // Фон
      TweenMax.fromTo('.section--useful .js-tween-bg', 0.8, {top: "100%"}, {top: "-60px", ease: Expo.easeOut, delay: 0.6});
    };

    // 7 - секция (СКРЫТИЕ)
    let seventhPageHide = function () {
      let
        tl = new TimelineMax({delay: 0.1}),
        tl2 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerTo('.section--client .js-tween-title span span', 0.7, {
        x: 80,
        opacity: 0,
        ease: Expo.easeInOut,
        delay: 0.4
      }, 0.01);

      // Текст
      tl.staggerTo('.section--client .js-tween-text', 0.6, {
        x: 200,
        opacity: 0,
        ease: Expo.easeInOut,
        delay: 0.2
      }, 0.05);

      // Картинка
      TweenMax.staggerTo('.section--client .tns-slide-active .client-item > span', 0.6, {width: "100%", ease: Expo.easeInOut, delay: 0.2}, 0.1);

      // Стрелки для слайдера
      TweenMax.staggerTo('.section--client .js-tween-control', 0.6, {x: 80, opacity: 0, ease: Expo.easeInOut, delay: 0}, -0.2);

      // Кнопки
      TweenMax.to('.section--client .js-tween-btn', 0.5, buttonHideAnimation);

      // Фон
      TweenMax.to('.section--client .js-tween-bg', 0.7, {width: 0, ease: Expo.easeInOut, delay: 0.3});

      // Фон для контента
      TweenMax.to('.section--client .js-content-bg', 0.7, {yPercent: 100, ease: Expo.easeInOut, delay: 0.7});
    };

    // 7 - секция (ПОКАЗ)
    let seventhPageShow = function () {
      let
        tl = new TimelineMax({delay: 0.8}),
        tl2 = new TimelineMax();

      // Текст Заголовки
      tl2.staggerFromTo('.section--client .js-tween-title span span', 0.6, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeInOut,
        delay: 0
      }, 0.01);

      // Текст
      tl.staggerFromTo('.section--client .js-tween-text', 1.2, {x: -100, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
      }, 0.05);

      // Картинка
      TweenMax.staggerFromTo('.section--client .tns-slide-active .client-item > span', 0.6, {width: "100%"}, {width: 0, ease: Expo.easeOut, delay: 0.6}, 0.1);

      // Кнопки
      TweenMax.fromTo('.section--client .js-tween-btn', 0.4, buttonHideAnimation, {clearProps: "all", ease: Expo.easeInOut, delay: 0.3});

      // Стрелки для слайдера
      TweenMax.staggerFromTo('.section--client .js-tween-control', 0.6, {x: -80, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut, delay: 0.6}, -0.2);

      // Фон
      TweenMax.fromTo('.section--client .js-tween-bg', 0.6, {width: 0}, {width: "28%", ease: Expo.easeOut, delay: 0.4});

      // Фон для контента
      TweenMax.fromTo('.section--client .js-content-bg', 0.6, {yPercent: 100}, {yPercent: 0, ease: Expo.easeOut, delay: 0.4});
    };


    // Финальная - секция (СКРЫТИЕ)
    let lastPageHide = function () {
      let
        tl = new TimelineMax({delay: 0.4}),
        tl2 = new TimelineMax();

      // Заголовок
      tl.staggerTo('.section--footer .js-tween-scale', 0.8, {
        scale: 0,
        opacity: 0,
        ease: Expo.easeInOut,
      }, -0.1);

      // Кнопка
      TweenMax.to('.section--footer .js-tween-moveUP', 0.7, {y: -100, opacity: 0, ease: Expo.easeInOut, delay: 0.4});

      // Плашка в самом низу
      TweenMax.to('.section--footer .js-tween-footer', 0.7, {y: 100, ease: Expo.easeInOut, delay: 0.2});

      // Текст
      tl2.staggerTo('.section--footer .js-tween-text', 0.6, {
        x: 120,
        opacity: 0,
        ease: Expo.easeIn,
        delay: 0
      }, -0.012);
    };

    // Финальная - секция (ПОКАЗ)
    let lastPageShow = function () {
      let
        tl = new TimelineMax({delay: 0.25}),
        tl2 = new TimelineMax();

      // Заголовок
      tl.staggerFromTo('.section--footer .js-tween-scale', 0.8, {scale: 0, opacity: 0}, {
        scale: 1,
        opacity: 1,
        ease: Expo.easeOut,
        delay: 0
      }, 0.1);

      // Кнопка
      TweenMax.fromTo('.section--footer .js-tween-moveUP', 0.8, {y: 100, opacity: 0}, {y: 0, opacity: 1, ease: Expo.easeOut, delay: tl.duration() - 0.6});

      // Плашка в самом низу
      TweenMax.fromTo('.section--footer .js-tween-footer', 0.8, {y: 100}, {y: 0, ease: Expo.easeOut, delay: tl.duration() - 0.4});

      // Текст
      tl2.staggerFromTo('.section--footer .js-tween-text', 1.2, {x: -80, opacity: 0}, {
        x: 0,
        opacity: 1,
        ease: Expo.easeOut,
        delay: tl.duration() - 0.6
      }, 0.015);
    };

    $('#fullpage').fullpage({
      licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
      autoScrolling: true,
      scrollHorizontally: true,
      anchors: ['page1', 'page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8'],
      normalScrollElements: '.main-thumbnails, .main-nav, .modal-item',
      navigation: true,
      navigationPosition: 'left',
      touchSensitivity: 1000,
      fitToSectionDelay: 5000,
      scrollingSpeed: (!window.power.low) ? 1000 : 800,

      afterLoad: function (e, d) {
        $('.js-scroll-down').attr('href', '#page' + (d.index + 2));
      },

      onLeave: function (e, d) {

        if (window.power.low) {
          // Анимация скрытия элементов 1 страницы
          if (d.index !== 0) {
            firstPageHide();
          }
          // Анимация показа элементов 1 страницы
          if (d.index === 0) {
            setTimeout(function () {
              firstPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 2 страницы
          if (d.index !== 1) {
            secondPageHide();
          }
          // Анимация показа элементов 2 страницы
          if (d.index === 1) {
            setTimeout(function () {
              secondPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 3 страницы
          if (d.index !== 2) {
            thirdPageHide();
          }
          // Анимация показа элементов 3 страницы
          if (d.index === 2) {
            setTimeout(function () {
              thirdPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 4 страницы
          if (d.index !== 3) {
            fourthPageHide();
          }
          // Анимация показа элементов 4 страницы
          if (d.index === 3) {
            setTimeout(function () {
              fourthPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 5 страницы
          if (d.index !== 4) {
            fifthPageHide();
          }
          // Анимация показа элементов 5 страницы
          if (d.index === 4) {
            setTimeout(function () {
              fifthPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 6 страницы
          if (d.index !== 5) {
            sixthPageHide();
          }
          // Анимация показа элементов 6 страницы
          if (d.index === 5) {
            setTimeout(function () {
              sixthPageShow();
            }, 1000)
          }

          // Анимация скрытия элементов 6 страницы
          if (d.index !== 6) {
            toggleClassWithDelay('.scroll-down', 'scroll-down--color');
            toggleClassWithDelay('#fp-nav', 'fp-nav-color-2', true);

            seventhPageHide();
          }
          // Анимация показа элементов 6 страницы
          if (d.index === 6) {
            setTimeout(function () {
              seventhPageShow();
            }, 1000);
            toggleClassWithDelay('.scroll-down', 'scroll-down--color', true);
            toggleClassWithDelay('#fp-nav', 'fp-nav-color-2');
          }


          // Анимация скрытия элементов Финальной страницы
          if (d.index !== 7) {
            lastPageHide();
          }
          // Анимация показа элементов Финальной страницы
          if (d.index === 7) {
            setTimeout(function () {
              lastPageShow();
            }, 1000)
          }
        }


        // Если не напервой странице то к шапке добавляется класс
        if (d.index === $('#fullpage > .section').length - 1) {
          setTimeout(function () {
            $('.header').addClass('header--hide');
            $('.scroll-down').addClass('scroll-down--hide');
          }, 800)
        } else {
          setTimeout(function () {
            $('.header').removeClass('header--hide');
            $('.scroll-down').removeClass('scroll-down--hide');
          }, 1000)
        }

        // Если не напервой странице то к шапке добавляется класс
        if (d.index > 0) {
          toggleClassWithDelay('.header', 'header--top');
        } else {
          toggleClassWithDelay('.header', 'header--top', true);
        }

        // Если не напервой странице то к навигационной плашке добавляется класс
        if (d.index > 0) {
          toggleClassWithDelay('#fp-nav', 'fp-nav-color');
        } else {
          toggleClassWithDelay('#fp-nav', 'fp-nav-color', true);
        }

        // Если не напервой странице то к символу прокурутки добавляется класс
        if (d.index > 0) {
          toggleClassWithDelay('.scroll-down', 'scroll-down--color');
        } else {
          toggleClassWithDelay('.scroll-down', 'scroll-down--color', true);
        }
      }
    });
  })();
}

//  Дефолтный поток
(function () {

  if (document.querySelectorAll('.js-modal').length > 0) {
    document.querySelectorAll('.js-modal').forEach(function (current) {
      window.modal.alexModal(current);
    });
  }

  if (window.matchMedia("(min-width: 1000px)").matches) {
    (function () {
      if (document.querySelector('.js-scroll-down')) {
        let tl = new TimelineMax({repeat: -1, repeatDelay: 5});

        tl
          .staggerTo('.scroll-down__icon', 1, {y: -30, ease: Expo.easeInOut}, 0.05)
          .staggerTo('.scroll-down__icon', 2.5, {y: 0, ease: Elastic.easeOut}, 0.05, "-=0.1")
      }
    })();
  }

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.main-slider')) {

        let slider = tns({
          container: '.main-slider',
          items: 1,
          slideBy: 'page',
          mode: 'gallery',
          mouseDrag: true,
          speed: 0,
          navContainer: '#main-slider-thumbnails',
          controlsContainer: '#main-slider-controls',
          navAsThumbnails: true,
        });

        let setInfoSlider = function (e) {
          let
            contentBlock = document.querySelectorAll('.js-slider-content'),
            contentBlockActive = document.querySelector('.js-slider-content.active') || false;

          contentBlock = (contentBlock.length !== 0) ? contentBlock : false;

          if (contentBlock) {
            contentBlockActive.classList.remove('active');
            contentBlock[e.displayIndex - 1].classList.add('active');
          }
        };

        setInfoSlider(slider.getInfo());

        slider.events.on('transitionStart', setInfoSlider);

        slider.events.on('transitionEnd', function (e) {
          document.querySelector('.tns-nav-active').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

          TweenMax.staggerFromTo(
            '.main-slider .tns-slide-active .js-text-span span span',
            0.6,
            {x: 100, opacity: 0,},
            {x: 0, opacity: 1, ease: Ease.easeIn},
            0.008
          );

          TweenMax.staggerFromTo(
            '.main-slider .tns-slide-active .js-text-span div > span',
            0.4,
            {x: 10},
            {x: 0, ease: Ease.easeOut, delay: 0.4},
            0.01
          )
        });
      }
    })
  })();

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.product-slider')) {

        let sliderActive = function (info) {
          let
            indexPrev = info.indexCached,
            indexCurrent = info.index,
            indexNext = info.index + 1,
            sliderLength = info.slideCount;

          info.slideItems[indexPrev].classList.remove('active');
          info.slideItems[indexCurrent].classList.add('active');

          if (indexNext !== sliderLength) {
            info.slideItems[indexNext].addEventListener("click", function () {
              slider.goTo(indexNext);
            })
          }
        };

        let slider = tns({
          container: '.product-slider',
          items: 1,
          slideBy: 'page',
          mouseDrag: true,
          rewind: true,
          speed: 600,
          edgePadding: 400,
          gutter: 100,
          onInit: sliderActive,
          controlsContainer: '#product-slider-controls',
          navContainer: '#product-nav-container',
          responsive: {
            1440: {
              edgePadding: 400,
              gutter: 100,
            },
            1280: {
              edgePadding: 350,
              gutter: 50,
            },
            768: {
              edgePadding: 0,
              gutter: 50,
              autoHeight: false,
            },
            320: {
              edgePadding: 0,
              gutter: 50,
              autoHeight: true,
            },
          }
        });

        slider.events.on('transitionStart', sliderActive);
      }
      //do work
    });
  })();

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.client-slider')) {

        let slider = tns({
          container: '.client-slider',
          items: 4,
          slideBy: '1',
          mouseDrag: true,
          rewind: true,
          speed: 600,
          gutter: 50,
          controlsContainer: '#client-slider-controls',
          responsive: {
            1440: {
              items: 4,
            },
            1280: {
              items: 4,
              autoplay: false,
            },
            768: {
              items: 4,
            },
            320: {
              items: 2,
              autoplay: true,
            },
          }
        });
      }
    })
  })();

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelectorAll('.js-useful-slider').length > 0) {
        [].forEach.call(document.querySelectorAll('.js-useful-slider'), function (container) {
          let
            sliderElement = container.querySelector('.js-slider'),
            sliderNavContainer = "#" + container.querySelector('.js-slider-nav').id,
            sliderControlContainer = "#" + container.querySelector('.js-slider-control').id;

          let sliderActive = function (info) {

            let
              indexPrev = info.indexCached,
              indexCurrent = info.index,
              indexNext = info.index + 1,
              indexDoubleNext = info.index + 2,
              sliderLength = info.slideCount;

            info.slideItems[indexPrev].classList.remove('slider-active');
            info.slideItems[indexCurrent].classList.add('slider-active');

            if (indexNext < sliderLength) {
              info.slideItems[indexNext].addEventListener("click", function () {
                slider.goTo(indexNext);
              })
            }

            if (indexDoubleNext < sliderLength) {
              info.slideItems[indexDoubleNext].addEventListener("click", function () {
                slider.goTo(indexDoubleNext);
              })
            }
          };

          let slider = tns({
            container: sliderElement,
            items: 1,
            slideBy: 'page',
            rewind: true,
            speed: 600,
            edgePadding: 400,
            mouseDrag: false,
            touch: false,
            gutter: 30,
            onInit: sliderActive,
            navContainer: sliderNavContainer,
            controlsContainer: sliderControlContainer,
            responsive: {
              1580: {
                items: 1,
                edgePadding: 900,
                gutter: 60,
              },
              1440: {
                items: 1,
                edgePadding: 700,
                gutter: 60,
              },
              1280: {
                items: 1,
                edgePadding: 760,
                gutter: 120,
                touch: false,
              },
              1000: {
                items: 1,
                edgePadding: 600,
                gutter: 40,
                touch: true,
              },
              768: {
                items: 2,
                edgePadding: 0,
                gutter: 40,
                touch: true,
                autoHeight: false,
              },
              320: {
                items: 1,
                edgePadding: 0,
                gutter: 60,
                touch: true,
                autoHeight: true,
              },
            }
          });

          slider.events.on('transitionStart', sliderActive);
        });
      }
    })
  })();

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelector('.js-catalog-slider')) {

        let slider = tns({
          container: '.js-catalog-slider',
          items: 1,
          slideBy: '1',
          mouseDrag: true,
          rewind: true,
          speed: 600,
          gutter: 0,
          controlsContainer: '#catalog-control',
          navContainer: '#catalog-nav',
          responsive: {
            768: {
              items: 1,
            },
            320: {
              items: 1,
              autoplay: true,
            },
          }
        });
      }
    })
  })();

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelectorAll('.js-photo-slider-parent').length > 0) {
        [].forEach.call(document.querySelectorAll('.js-photo-slider-parent'), function (container) {
          let
            sliderElement = container.querySelector('.js-photo-slider'),
            sliderNavContainer = "#" + container.querySelector('.js-slider-nav').id;

          let sliderActive = function (info) {

            setTimeout(function () {
              if (info.container.parentNode.parentNode.style.height === "0px")
                info.container.parentNode.parentNode.style.height = "auto";
            }, 400);

          };

          let slider = tns({
            container: sliderElement,
            items: 1,
            slideBy: '1',
            rewind: true,
            mouseDrag: true,
            speed: 600,
            controls: false,
            navContainer: sliderNavContainer,
            onInit: sliderActive,
            responsive: {
              768: {
                autoHeight: false,
              },
              320: {
                autoHeight: true,
              },
            }
          });
        });
      }
    })
  })();

  /*document.querySelectorAll('.js-modal').forEach(function (current) {
    window.modal.alexModal(current);
  });*/

  (function () {
    document.addEventListener("DOMContentLoaded", function (event) {
      if (document.querySelectorAll('.js-content-slider-parent').length > 0) {
        [].forEach.call(document.querySelectorAll('.js-content-slider-parent'), function (container) {
          let
            sliderElement = container.querySelector('.js-content-slider'),
            sliderControlContainer = container.querySelector('.js-content-slider-control');

          let slider = tns({
            container: sliderElement,
            items: 1,
            slideBy: '1',
            mouseDrag: true,
            rewind: true,
            speed: 600,
            gutter: 1,
            autoHeight: true,
            controlsContainer: sliderControlContainer,
            responsive: {
              768: {
                items: 1,
              },
              320: {
                items: 1,
              },
            }
          });
        })
      }
    })
  })();

  if (document.querySelectorAll('.js-tab-parent').length) {
    document.querySelectorAll('.js-tab-parent').forEach(function (current) {
      window.tab.tabWork(current);
    });
  }

})();

(function () {
  if (window.matchMedia("(min-width: 1370px)").matches) {
    document.querySelectorAll('.js-hover-parent').forEach(function (current) {
      window.tabHover.tabHoverWork(current);
    });
  } else {
    document.querySelectorAll('.js-hover-parent').forEach(function (current) {
      current.querySelector('.js-hover-item').classList.add('js-tab-item');
      current.querySelector('.js-hover-target').classList.add('js-tab-target');

      window.tab.tabWork(current);
    });
  }
})();


// Анимация главного меню
(function () {
  let
    menu = document.querySelector(".js-menu"),
    mainNav = document.querySelector(".main-nav");

  let
    tl3 = new TimelineMax(),
    tl4 = new TimelineMax(),
    tl5 = new TimelineMax();


  if (window.matchMedia("(min-width: 769px)").matches && window.power.low) {
    tl3
      .to('.main-nav__left,.main-nav__right', 0.8, {x: 0, xPercent: 0, ease: Expo.easeOut, delay: 0.1})
      .staggerFromTo('.main-nav .main-nav__header > *', 0.6, {y: -200, opacity: 0}, {y: 0, opacity: 1, ease: Expo.easeOut}, 0.1, "-=0.4")
      .staggerFromTo('.main-nav .menu-list > li', 0.8, {x: -160, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut}, 0.05, "-=0.8")
      .staggerFromTo('.main-nav .main-nav__footer > *', 0.8, {x: -160, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut}, 0.05, "-=1");

    tl4
      .to(".header-menu-btn > div", 1.2, {x: 200, opacity: 0, ease: Expo.easeInOut})
      .set(".header-menu-btn > div", {display: "none"}, "-=0.4")
      .set(".header-menu-btn > span", {display: "block"}, "-=0.4")
      .fromTo(".header-menu-btn > span", 0.8, {opacity: 0, x: 200, scale: 0}, {opacity: 1, x: 0, scale: 1, ease: Expo.easeInOut}, "-=0.75");

    tl5
      .fromTo('.main-nav .menu-sub-list-wrap > li .menu-sub-list > li', 1, {x: -80, opacity: 0}, {x: 0, opacity: 1, ease: Expo.easeOut, delay: 0.6}, 0.05);

    tl3.pause();
    tl4.pause();
    tl5.pause();
  }

  let openMenu = function () {
    if (mainNav.getAttribute('aria-hidden') === "true") {
      mainNav.setAttribute('aria-hidden', "false");

      TweenMax.set(mainNav, {display: "block"});
      TweenMax.set(menu, {className: "-=js-menu"});
      TweenMax.set(menu, {className: "+=js-menu-close"});
      TweenMax.set("body", {className: "+=open-menu"});

      if (window.matchMedia("(min-width: 769px)").matches && window.power.low) {
        tl3.restart();
        tl4.restart();
        tl5.restart();
      }
    } else {
      mainNav.setAttribute('aria-hidden', "true");


      if (window.matchMedia("(min-width: 769px)").matches && window.power.low) {
        TweenMax.set(mainNav, {display: "none", delay: tl3.duration()});
      } else {
        TweenMax.set(mainNav, {display: "none"});
      }
      TweenMax.set(menu, {className: "+=js-menu"});
      TweenMax.set(menu, {className: "-=js-menu-close"});
      TweenMax.set("body", {className: "-=open-menu", delay: tl3.duration()});

      if (window.matchMedia("(min-width: 769px)").matches && window.power.low) {
        tl3.reverse();
        tl4.reverse();
        tl5.reverse();
      }
    }
  };

  let menuClickHandler = function () {
    openMenu();
  };

  menu.addEventListener("click", menuClickHandler);
})();

(function () {
  if (window.matchMedia("(max-width: 480px)").matches) {
    let
      mainMenu = document.querySelector(".menu-list-wrap"),
      mainMenuItem = mainMenu.querySelectorAll('.menu-list.js-tab-item > li .menu-list__icon');

    let clickButtonBackHandler = function () {
      let
        tl = new TimelineMax();

      tl
        .to(mainMenu, 0.8, {xPercent: 0, x: 0, ease: Expo.easeInOut})
        .set(".menu-list.js-tab-item", {height: "auto"})
        .set(".menu-sub-list-wrap.js-tab-target", {height: 0});

      let
        mainMenuBack = mainMenu.querySelector('.menu-sub-list-wrap.js-tab-target > li.active button');

      mainMenuBack.removeEventListener('click', clickButtonBackHandler);
    };

    let clickMenuItemHandler = function () {
      let tl = new TimelineMax();

      tl
        .to(mainMenu, 0.8, {xPercent: -100, x: -30, ease: Expo.easeInOut})
        .set(".menu-list.js-tab-item", {height: 0})
        .set(".menu-sub-list-wrap.js-tab-target", {height: "auto"});

      setTimeout(function () {
        let
          mainMenuBack = mainMenu.querySelector('.menu-sub-list-wrap.js-tab-target > li.active button');

        console.log(mainMenu.querySelector('.menu-sub-list-wrap.js-tab-target > li.active'));

        mainMenuBack.addEventListener('click', clickButtonBackHandler);
      }, 100);
    };

    if (mainMenuItem.length > 0) {
      mainMenuItem.forEach(function (current) {
        current.addEventListener('click', clickMenuItemHandler);
      });
    }
  }
})();

// Прелоадер
(function () {

  let preload = document.querySelector('.preload');

  if (preload) {
    // preload.remove(); // УДАЛИТЬ ПРИ ПРОДАКШЕНЕ
    setTimeout(function () {
      preload.classList.remove('preload--ready');
    }, 200);

    window.addEventListener('load', function () {
      preload.classList.add('child-hide');

      setTimeout(function () {
        preload.classList.add('active');
      }, 1500);


      setTimeout(function () {
        preload.remove();
      }, 3000);
    });
  }

})();

$(document).ready(function () {
  let
    counter = $('.js-preload-counter'),
    imagesCount = $('img').length, // количество изображений
    percent = 100 / imagesCount, // количество % на одну картинку
    progress = 0, // точка отсчета
    imgSum = 5, // количество картинок
    loadedImg = 0; // счетчик загрузки картинок

  if (imagesCount >= imgSum && imagesCount > 0) {

    for (let i = 0; i < imagesCount; i++) { // создаем клоны изображений
      let img_copy = new Image();

      img_copy.src = document.images[i].src;
      img_copy.onload = img_load;
      img_copy.onerror = img_load;
    }

    function img_load() {
      progress += percent;
      loadedImg++;

      if (window.matchMedia("(min-width: 480px)").matches) {
        if (progress <= 23) {
          $('.js-line-1 span').css('height', '100%');
        } else if (progress >= 23 && progress <= 32) {
          $('.js-line-2 span').css('width', '100%');
        } else if (progress >= 32 && progress <= 37) {
          $('.js-line-3 span').css('height', '100%');
          $('.js-preload-logo').css('width', '100%');
        } else if (progress >= 37 && progress <= 42) {
          $('.js-line-4 span').css('height', '100%');
        } else if (progress >= 42 && progress <= 51) {
          $('.js-line-5 span').css('width', '100%');
        } else if (progress >= 51) {
          $('.js-line-6 span').css('height', '100%');
        }
      }

      // counter.addClass('count-start');
      // counter.text(Math.round(progress) + '%');

      if (window.matchMedia("(max-width: 480px)").matches) {
        $('.js-preload-logo').css('width', progress + '%');
      }

    }
  }

});

// Быстрый выбор продукта на странице каталога
(function () {
  let select = document.querySelector('.js-filter-catalog');

  if (select) {
    select.addEventListener('change', function (evt) {
      let
        target = evt.target,
        currentOptionValue = '#' + target.selectedOptions[0].value;

      $('body, html').animate({
        scrollTop: $(currentOptionValue).offset().top - (100)
      }, 'slow');
    })
  }
})();

(function () {
  let catalogItems = document.querySelectorAll('.js-catalog-item');

  if (catalogItems.length && window.matchMedia("(min-width: 800px)").matches) {
    window.addEventListener('scroll', function (evt) {
      catalogItems.forEach(function (current) {
        if ((current.getBoundingClientRect().top - (current.getBoundingClientRect().height)) < 0) {
          current.classList.add('scrolled-active');
        }
      });
    });
  }
})();

// Высчитывание заполнения окружности в процентах
/*(function () {
  if (document.querySelector('.progress__value')) {
    let progressValue = document.querySelector('.progress__value');
    let RADIUS = 24;
    let CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    function progress(value) {
      let progress = value / 100;
      let dashoffset = CIRCUMFERENCE * (1 - progress);

      // console.log('progress:', value + '%', '|', 'offset:', dashoffset)

      progressValue.style.strokeDashoffset = dashoffset;
    }

    let percentRes = 80;

    // progressValue.style.strokeDasharray = CIRCUMFERENCE;
    // progress(percentRes);
  }
})();*/

// Запуск счётчика цифр в зависимости от скролла
(function () {
  window.onload = function () {
    function counter(element) {
      if (element.length) {
        element.forEach(function (current) {
          let numAnim = new countUp.CountUp(current, current.dataset.target, {
            useGrouping: false,
            duration: 3,
          });
          numAnim.start()
        });
      }
    }

    const el = document.querySelectorAll('.js-counter-parent');
    if (el.length) {
      el.forEach(function (current) {
        new Waypoint({
          element: current,
          handler: function () {
            counter(current.querySelectorAll('.js-counter'))
            this.destroy()
          },
          offset: '100%',
        })
      });
    }
  }

})();

// Заполнение круга прогресса в зависимости от скролла
(function () {
  let progressElement = document.querySelector('.js-progress-load');

  if (progressElement) {
    let
      percent = progressElement.querySelector('.js-counter').dataset.target,
      circleFill = progressElement.querySelector('.progress__value');

    const RADIUS = 22;
    const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

    function progress(value) {
      let progress = value / 100;

      circleFill.style.strokeDashoffset = CIRCUMFERENCE * (1 - progress);
    }

    new Waypoint({
      element: progressElement,
      handler: function () {
        circleFill.style.strokeDasharray = CIRCUMFERENCE;
        progress(+percent);
        this.destroy()
      },
      offset: '75%',
    })
  }
})();

// Заполнение круга прогресса в зависимости от скролла
(function () {
  let map = document.querySelector('.js-map-load');

  if (map) {
    new Waypoint({
      element: map,
      handler: function () {
        TweenMax.staggerTo('.js-map-tween-icon', 1.2, {y: 0, opacity: 1, ease: Bounce.easeOut}, 0.06);
        this.destroy()
      },
      offset: '85%',
    })
  }
})();

// Работа с меню
(function () {
  let menuButton = document.querySelectorAll('.lang-button');

  if (menuButton.length) {
    menuButton.forEach(function (current) {
      current.addEventListener('click', function (evt) {
        let target = evt.currentTarget;

        target.classList.toggle('menu-active');
      });

    });

    document.body.addEventListener('click', function (evt) {
      menuButton.forEach(function (current) {
        if (current.classList.contains('menu-active') && evt.target !== current) {
          current.classList.remove('menu-active');
        }
      });
    })
  }
})();

(function () {
  if ($('.js-pandora-parent').length) {
    window.collapse.wiyzjalka($('.js-pandora-hand'), '.js-pandora-parent', '.js-pandora-box', 'normal', false, 0);
  }
})();


// Вызов модалки с изменением поля select
(function () {
  let link = document.querySelectorAll('[data-select]');

  if (link.length > 0) {
    link.forEach(function (current) {
      let
        option = current.dataset.select,
        currentModal = current.dataset.id;

      current.addEventListener('click', function () {
        const select = document.querySelector(currentModal).querySelector('select');
        for (let i = 0; i < select.length; i++) {
          if (select[i].value === option) select[i].selected = true;
        }
      });
    })
  }
})();

// Загрузка файла
(function () {
  $('input[type="file"]').each(function () {
    $(this).change(function () {
      $(this).parents('.file-group').find('input[type=text]').val('"' + $(this).val().slice($(this).val().lastIndexOf("\\") + 1) + '"');
    });
  });
})();

// Отклик на вакансию
(function () {
  let vacancyLink = document.querySelectorAll('[data-vacancy]');

  if (vacancyLink.length > 0) {
    vacancyLink.forEach(function (current) {
      let
        vacancy = current.dataset.vacancy,
        currentModal = current.dataset.id;

      current.addEventListener('click', function () {
        let input = document.querySelector(currentModal).querySelector('[name="career-vacancy"]');

        input.value = vacancy;
      });
    })
  }
})();
